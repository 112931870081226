import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

import { Option, Props as OptionProps } from './Option'

export interface Props {
  disabledFirst?: string
  name: string
  error?: boolean
  options: OptionProps[]
  required?: boolean
  size?: 'M' | 'L'
  defaultValue?: string
  onBlur: FocusEventHandler<HTMLSelectElement>
  onChange?: ChangeEventHandler<HTMLSelectElement>
  onFocus?: FocusEventHandler<HTMLSelectElement>
}
export const Select = memo(
  forwardRef<HTMLSelectElement, Props>(function Select(
    {
      disabledFirst,
      name,
      error = false,
      options,
      required,
      size = 'M',
      defaultValue,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        error={error}
        className={`type-${size}`}
        name={name}
        required={required}
        defaultValue={defaultValue}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
      >
        {disabledFirst ? <Option value={disabledFirst} disabled /> : null}
        {options.map((item, index) => (
          <Option key={index} {...item} />
        ))}
      </Container>
    )
  }),
)

Select.displayName = 'Select'

const Container = styled.select<{ error: boolean }>`
  width: 48%;
  height: 3.5rem;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.875rem;
  background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 50%;
  color: ${({ theme }) => theme.colors.variants.base};
  border: 1px solid ${({ error }) => (error ? 'red' : 'white')};
  cursor: pointer;
  margin-top: 2rem;
  padding: 0 1rem;
  &.type-L {
    width: 100%;
  }

  option {
    color: black;
  }
`
