import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  disabled?: boolean
  hidden?: boolean
  label?: string
  value: string
}

export const Option = memo(function Option({
  disabled,
  hidden,
  label,
  value,
}: Props) {
  return (
    <Container value={value} disabled={disabled} hidden={hidden}>
      {label ? label : value}
    </Container>
  )
})

const Container = styled.option``
