import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Checkbox'
import { FlexBox } from 'app/components/FlexBox'
import { FormMessages } from 'app/components/FormMessages'
import { Input } from 'app/components/Input'
import { Select } from 'app/components/Select'
import { Spinner } from 'app/components/Spinner'
import { Textarea } from 'app/components/Textarea'
import {
  FormData,
  ResellersFormSenderBackend,
} from 'app/utils/ResellersFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import axios from 'axios'
import { isLeft } from 'fp-ts/lib/These'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useForm } from 'react-hook-form'

import countries from './countries.json'

const EMAIL_REGEX = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i

export interface Props {
  languageCode: string
  postSendRedirectPath: string
  onResellersFormSendToBackend: ResellersFormSenderBackend
}

export const ResellersForm = memo(function ResellersForm({
  languageCode,
  postSendRedirectPath,
  onResellersFormSendToBackend,
}: Props) {
  const formContainer = useRef<HTMLDivElement>(null)
  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [ipAddress, setIpAddress] = useState('')

  axios
    .get('https://geolocation-db.com/json/')
    .then((res) => {
      setIpAddress(res.data.IPv4)
    })
    .catch(function () {
      return false
    })

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onResellersFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        window.location.href = postSendRedirectPath
      }
    },
    [isSendingForm, onResellersFormSendToBackend, postSendRedirectPath],
  )

  const emailFromSearchParams = useMemo<string | undefined>(
    () =>
      typeof window !== 'undefined'
        ? new URLSearchParams(window.location.search).get('email') || undefined
        : undefined,
    [],
  )

  const { register, formState, handleSubmit } = useForm<FormData>({
    mode: 'onBlur',
    shouldFocusError: true,
    defaultValues: {
      referrer: typeof window !== 'undefined' ? window.location.href : '',
      email: emailFromSearchParams,
    },
  })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */
  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }
  }, [formState.errors])

  return (
    <Container ref={formContainer} dial={2}>
      <Form
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} />
        <Wrapper row wrap space="between">
          <Title>{useVocabularyData('personal-info', languageCode)}</Title>
          <Input type="hidden" {...register('referrer')} />
          <Input type="hidden" value={ipAddress} {...register('user_ip')} />
          <Input
            error={formState.errors.name !== undefined}
            isDirty={formState.dirtyFields.name}
            type="text"
            placeholder={`${useVocabularyData('first-name', languageCode)} *`}
            required={true}
            {...register('name', {
              required: true,
            })}
          />
          <Input
            error={formState.errors.lastname !== undefined}
            isDirty={formState.dirtyFields.lastname}
            type="text"
            placeholder={`${useVocabularyData('last-name', languageCode)} *`}
            required={true}
            {...register('lastname', {
              required: true,
            })}
          />
          <Input
            error={formState.errors.email !== undefined}
            isDirty={
              formState.dirtyFields.email || emailFromSearchParams !== undefined
            }
            type="email"
            placeholder={`${useVocabularyData('email', languageCode)} *`}
            required={true}
            {...register('email', {
              required: true,
              validate: (email) => EMAIL_REGEX.test(email),
            })}
          />
          <Input
            error={formState.errors.phone !== undefined}
            isDirty={formState.dirtyFields.phone}
            type="tel"
            placeholder={`${useVocabularyData('telephone', languageCode)} *`}
            required={true}
            {...register('phone', {
              required: true,
            })}
          />
          <Title>{useVocabularyData('property-data', languageCode)}</Title>
          <Input
            error={formState.errors.property_name !== undefined}
            isDirty={formState.dirtyFields.property_name}
            type="text"
            placeholder={`${useVocabularyData('name', languageCode)} *`}
            required={true}
            {...register('property_name', {
              required: true,
            })}
          />
          <Select
            error={formState.errors.property_type !== undefined}
            options={[
              { value: useVocabularyData('web-agency', languageCode) },
              {
                value: useVocabularyData('counselling-service', languageCode),
              },
              {
                value: useVocabularyData(
                  'software-distribution-production',
                  languageCode,
                ),
              },
              { value: useVocabularyData('other', languageCode) },
            ]}
            required={true}
            {...register('property_type', {
              required: true,
              validate: (property_type) => property_type != '',
            })}
          />
          <Input
            error={formState.errors.website !== undefined}
            isDirty={formState.dirtyFields.website}
            type="text"
            placeholder={`${useVocabularyData('website', languageCode)} *`}
            required={true}
            {...register('website', {
              required: true,
            })}
          />
          <Input
            error={formState.errors.address !== undefined}
            type="text"
            placeholder={useVocabularyData('address', languageCode)}
            {...register('address')}
          />
          <Input
            error={formState.errors.city !== undefined}
            type="text"
            placeholder={useVocabularyData('city', languageCode)}
            {...register('city')}
          />
          <Select
            error={formState.errors.country !== undefined}
            defaultValue={useVocabularyData('country', languageCode)}
            options={[
              {
                value: useVocabularyData('country', languageCode),
                disabled: true,
              },
              ...countries['en'].items.map((item) => item),
            ]}
            {...register('country')}
          />
          <Title>
            {useVocabularyData('how-did-you-hear-about', languageCode)}
          </Title>
          <Select
            size="L"
            defaultValue={useVocabularyData(
              'select-where-find-us',
              languageCode,
            )}
            options={[
              {
                value: useVocabularyData('select-where-find-us', languageCode),
                disabled: true,
              },
              {
                value: useVocabularyData('search-engine-google', languageCode),
              },
              {
                value: useVocabularyData('search-engine-others', languageCode),
              },
              {
                value: useVocabularyData(
                  'link-from-hotel-website',
                  languageCode,
                ),
              },
              { value: useVocabularyData('word-of-mouth', languageCode) },
              {
                value: useVocabularyData(
                  'trip-advisor-metasearch',
                  languageCode,
                ),
              },
              {
                value: useVocabularyData('social-media-property', languageCode),
              },
              { value: useVocabularyData('blog-forum', languageCode) },
              { value: useVocabularyData('newsletter', languageCode) },
              { value: useVocabularyData('banner', languageCode) },
              { value: useVocabularyData('trade-show', languageCode) },
              { value: useVocabularyData('print-advertising', languageCode) },
              { value: useVocabularyData('other', languageCode) },
            ]}
            {...register('reference')}
          />
          <Title>
            {useVocabularyData('additional-information', languageCode)}
          </Title>
          <Textarea
            error={formState.errors.request !== undefined}
            placeholder={useVocabularyData('request', languageCode)}
            required={false}
            {...register('request')}
          />
          <Checkbox
            label={useVocabularyData('sign-up-newsletter', languageCode)}
            {...register('newsletter_subscription')}
          />
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('data-protection', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
          {displaySendErrorFeedback ? (
            <FormMessages
              text={useVocabularyData('form-error-message', languageCode)}
            />
          ) : null}
          <Input
            name="sumbit"
            type="submit"
            value={useVocabularyData('submit', languageCode)}
            variant="submit"
          />
        </Wrapper>
      </Form>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background-color: black;
  border-bottom: 1px solid white;
  padding-bottom: 5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`

const Title = styled.h2`
  width: 100%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.variants.base};
  margin: 5rem auto 0 auto;
  text-align: center;

  @media all and (max-width: 571px) {
    font-size: 1.8rem;
  }
`

const Form = styled.form`
  width: 100%;
  max-width: 1000px;
  position: relative;

  @media all and (max-width: 600px) {
    input,
    select,
    textarea {
      width: 100%;
      display: block;
      max-width: none;
    }
  }
`
const Wrapper = styled(FlexBox)``
